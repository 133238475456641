export default class ToneGenerator {
  // static oscTypes = ['sine', 'square', 'sawtooth', 'triangle', 'custom'];

  constructor(type = 'sine', freq = 432) {
    this.audioCtx     = null;
    this.osc          = null;
    this.oscType      = type;
    this.oscFrequency = freq;

    this.createAudioCtx();
  }

  createAudioCtx() {
    if (this.audioCtx) { 
      this.audioCtx = null; 
    }
    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  }

  createOsc(freq = 432, type = 'sine') {
    this.oscType = type;
    this.oscFrequency = freq;

    this.osc = this.audioCtx.createOscillator();
    this.osc.type = type;
    this.osc.frequency.setValueAtTime(freq, this.audioCtx.currentTime);
    this.osc.connect(this.audioCtx.destination);
  }

  create(type, freq) {
    this.createAudioCtx();
    this.createOsc(type, freq);
  }

  setOscType(type) {
    return this.oscType = type;
  }

  setOscFrequency(frequency) {
    return this.oscFrequency = frequency;
  }

  getOscType() {
    return this.oscType;
  }

  getOscFrequency() {
    return this.oscFrequency;
  }

  startOsc() {
    this.osc.start();
  }

  stopOsc() {
    this.osc.stop();
  }

  disconnectOsc() {
    this.osc.disconnect();
  }

  resetOsc() {
    if (this.osc) {
      this.stopOsc();
      this.disconnectOsc();
      this.osc = null; // can't stop / start osc more than once, so destroy
    }
  }

  handleStart(freq) {
    if (!this.osc) {
      this.createOsc(freq);
      this.startOsc();
    } else if (freq && freq !== this.oscFrequency) {
      this.resetOsc();
      this.createOsc(freq);
      this.startOsc();
    }
  }

  handleStop() {
    this.resetOsc();
  }

} // Tone Generator

// export { ToneGenerator };
