import ToneGenerator from './modules/ToneGenerator.js';

(function() {

  const app = {
    audioCtx: null,
    osc: null,
    oscType: null,
    oscFrequency: null,

    init: function() {
      this.cacheDom();
      this.bindEvents();
      this.render();
    },

    cacheDom: function() {
      this.freq     = document.getElementById('frequency');
      this.btnStart = document.getElementById('start');
      this.btnStop  = document.getElementById('stop');
    },

    bindEvents: function() {
      this.btnStart.addEventListener('click', this.handleStart.bind(this));
      this.btnStop.addEventListener('click', this.handleStop.bind(this));
    },

    handleStart: function(e) {
      e.preventDefault();
      this.osc.handleStart(this.freq.value);
    },

    handleStop: function(e) {
      e.preventDefault();
      this.osc.handleStop();
    },

    render: function() {
      this.osc = new ToneGenerator('sine', 432);
    }
  };

  app.init();
  
})();
